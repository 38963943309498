<section class="work-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div *ngIf="cargandoDatos">
                    <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
                </div>
                <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
                    <h1><i class="fas fa-wrench"></i></h1>
                    <h3>Sin contenido</h3>
                </div>
                <div class="blog-details" *ngIf="!sinContenido && !cargandoDatos">
                    <div class="article-text" [innerHtml]="descripcionCurso.descripcion | safeHtml">
                    </div>
                </div>
            </div>
            <br><br>
        </div>
        <br><br>
        <div class="container" *ngIf="!sinContenido && !cargandoDatos">
            <div class="row">
                <div class="col-lg-4 col-md-4" *ngFor="let curso of cursos">
                    <!-- <a (click)="abrirModal(curso.idPrograma)"> -->
                    <a [routerLink]="['/detalle-programa', curso.idPrograma]">
                        <div class="single-services">
                            <div class="services-img">
                                <img [src]="IMG_HOST + curso.rutaImagen" alt="services-img">
                            </div>
                            <div class="services-content">
                                <h5>{{curso.nombre}}</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- MODAL DETALLE CURSO -->
<div class="nuestrosDerechos-popup">
    <ngx-smart-modal #detalleCurso [identifier]="'detalleCurso'">
        <div class="modal-content">
            <div *ngIf="detalleCurso.hasData()">
                <div class="modal-header">
                    <div class="section-titleN">
                        <h3>{{curso.nombre}}</h3>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="blog-details-area">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8 col-md-12">
                                    <div class="blog-details">
                                        <br>
                                        <div class="text-center">
                                            <img [src]="IMG_HOST + curso.rutaImagen" class="rounded" alt="...">
                                        </div>
                                        <div class="article-text" [innerHtml]="curso.descripcion | safeHtml">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="detalleCurso.close()">Cerrar</button>
            </div>
        </div>
    </ngx-smart-modal>
</div>