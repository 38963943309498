import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursosService } from 'src/app/servicios/destacadas/cursos.service';
import { ProgramasService } from 'src/app/servicios/destacadas/programas.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-detallePrograma',
  templateUrl: './detalleCurso.component.html',
  // styleUrls: ['./detalleCurso.component.scss']
})
export class DetalleProgramaComponent implements OnInit {

  public idPrograma;
  public curso: any = {};
  public IMG_HOST = environment.API_IMAGEN;
  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(
    private _router: ActivatedRoute,
    private cursos_service: ProgramasService
  ) {
    this._router.params.subscribe(res => this.idPrograma = res.idPrograma);
    this.title = 'Cursos';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  ngOnInit() {
    this.obtenerCurso(this.idPrograma);
  }

  obtenerCurso(idPrograma) {
    this.cursos_service.getPrograma(idPrograma).subscribe(response => {
      this.curso = response[0];
    });
    // this.ngxSmartModalService.setModalData(this.curso, 'detalleCurso');
  }

}
