import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ProgramasService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getDescripcionPrograma(){
    return this.http.get(`${this.API_URI}/programas/descripcion`)
  }

  getProgramas() {
    return this.http.get(`${this.API_URI}/programas`)
  }

  getPrograma(id: string) {
    return this.http.get(`${this.API_URI}/programas/${id}`);
  }

}
