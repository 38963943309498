import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './components/layout/layout.component';
import { AvisosPrivacidadComponent } from './components/secciones/avisosPrivacidad/avisosPrivacidad.component';
import { BibliotecaComponent } from './components/secciones/destacadas/biblioteca/biblioteca.component';
import { UnidadBibliotecaComponent } from './components/secciones/destacadas/biblioteca/unidadBiblioteca.component';
import { ChatComponent } from './components/secciones/destacadas/chat/chat.component';
import { ConvocatoriasComponent } from './components/secciones/destacadas/convocatorias/convocatorias.component';
import { CursosComponent } from './components/secciones/destacadas/cursos/cursos.component';
import { DetalleCursoComponent } from './components/secciones/destacadas/cursos/detalleCurso.component';
import { FormularioQuejaComponent } from './components/secciones/destacadas/organoControlInterno/componentes/presentaDenuncia/formularioQueja/formularioQueja.component';
import { DetalleNotaComponent } from './components/secciones/detalleNota/detalleNota.component';
import { DetalleConcursoComponent } from './components/secciones/difusion/componentes/concursos/detalleConcurso.component';
import { DetalleGaleriaComponent } from './components/secciones/difusion/componentes/galeria/detalleGaleria.component';
import { AudiosComponent } from './components/secciones/difusion/componentes/programasRadio/audios.component';
import { ProgramasComponent } from './components/secciones/destacadas/programas/programas.component';
import { DetalleProgramaComponent } from './components/secciones/destacadas/programas/detalleCurso.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./components/inicio/inicio.module').then(m => m.InicioModule)
            },
            {
                path: 'codhey',
                loadChildren: () => import('./components/secciones/codhey/codhey.module').then(m => m.CodheyModule)
            },
            {
                path: 'derechos-humanos',
                loadChildren: () => import('./components/secciones/derechosHumanos/derechosHumanos.module').then(m => m.DerechosHumanosModule)
            },
            {
                path: 'difusion',
                loadChildren: () => import('./components/secciones/difusion/difusion.module').then(m => m.DifusionModule)
            },
            {
                path: 'ninos-ninas',
                loadChildren: () => import('./components/secciones/seccionNinos/seccionNinos.module').then(m => m.SeccionNinosModule)
            },
            {
                path: 'micro-sitios',
                loadChildren: () => import('./components/secciones/microSitios/microSitios.module').then(m => m.MicroSitiosModule)
            },
            {
                path: 'contacto',
                loadChildren: () => import('./components/secciones/contacto/contacto.module').then(m => m.ContactoModule)
            },
            {
                path: 'recomendaciones',
                loadChildren: () => import('./components/secciones/recomendaciones/recomendaciones.module').then(m => m.RecomendacionesModule)
            },
            {
                path: 'transparencia',
                loadChildren: () => import('./components/secciones/destacadas/transparencia/transparencia.module').then(m => m.TransparenciaModule)
            },
            {
                path: 'organo-control-interno',
                loadChildren: () => import('./components/secciones/destacadas/organoControlInterno/organoControlInterno.module').then(m => m.OrganoControlInternoModule)
            },
            {
                path: 'sistema-control-interno',
                loadChildren: () => import('./components/secciones/destacadas/sistemaControlInterno/sistemaControlInterno.module').then(m => m.SistemaControlInternoModule)
            },
            {
                path: 'presenta-queja',
                loadChildren: () => import('./components/secciones/destacadas/transparencia/transparencia.module').then(m => m.TransparenciaModule)
            },
            {
                path: 'grupos-vulnerables',
                loadChildren: () => import('./components/secciones/destacadas/gruposVulnerables/gruposVulnerables.module').then(m => m.GruposVulnerablesModule)
            },
            {
                path: 'detalle-nota/:idBoletin',
                component: DetalleNotaComponent
            },
            {
                path: 'detalle-concurso/:idConcurso',
                component: DetalleConcursoComponent
            },
            {
                path: 'convocatorias',
                component: ConvocatoriasComponent
            },
            {
                path: 'audios/:idProgramaRadio',
                component: AudiosComponent
            },
            {
                path: 'detalle-galeria/:idGaleria',
                component: DetalleGaleriaComponent
            },
            {
                path: 'cursos',
                component: CursosComponent
            },
            {
                path: 'detalle-curso/:idCurso',
                component: DetalleCursoComponent
            },
            {
                path: 'programas',
                component: ProgramasComponent
            },
            {
                path: 'detalle-programa/:idPrograma',
                component: DetalleProgramaComponent
            },
            {
                path: 'biblioteca',
                // component: BibliotecaComponent
                component: UnidadBibliotecaComponent
            },
            {
                path: 'acervo-biblioteca',
                // component: BibliotecaComponent
                component: BibliotecaComponent
            },
            {
                path: 'formulario-queja',
                component: FormularioQuejaComponent
            },
            {
                path: 'avisos-privacidad',
                component: AvisosPrivacidadComponent
            },
            {
                path: 'chat-codhey',
                component: ChatComponent
            },
        ]
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})

export class AppRoutingModule { }