export const environment = {
  // production: true

  //CONEXIÓN LOCAL
  // API_URL: 'http://localhost:3000',
  // API_ANGULAR: `https://localhost/archivos_codhey/assets/`,
  // RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
  // API_SERVER_PHP: 'http://localhost/archivos-codhey/enviarCorreo.php',
  // API_PDF: `http://localhost/archivos-codhey/documentos/`,
  // API_IMAGEN: `http://localhost/archivos-codhey/imagenes/`,
  // API_AUDIO: `http://localhost/archivos-codhey/audios/`


  // CONEXIONES PARA DREAMHOST Y GO DADDY
  // SERVIDOR
  // API_URL: 'http://134.122.23.70:3000',
  API_URL: 'https://codhey.org.mx',
  //ARCHIVOS
  API_SERVER_PHP: 'https://apicultoresunidos.com/enviarCorreo.php',
  API_PDF: `https://pruebaadmin.codhey.org/archivos-codhey/documentos/`,
  API_IMAGEN: `https://pruebaadmin.codhey.org/archivos-codhey/imagenes/`,
  API_AUDIO: `https://pruebaadmin.codhey.org/archivos-codhey/audios/`





};
