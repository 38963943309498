import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ProgramasService } from 'src/app/servicios/destacadas/programas.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
})
export class ProgramasComponent implements OnInit {

  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public IMG_HOST = environment.API_IMAGEN;
  public descripcionCurso: any = {};
  public cursos: any = [];
  public curso: any = {};
  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(public ngxSmartModalService: NgxSmartModalService, private cursos_service: ProgramasService) {
    /* Configuración del módulo */
    this.title = 'PROGRAMAS';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/codheyf.jpg'
  }

  ngOnInit() {
    this.obtenerDescripcion();
    this.obtenerCursos();
  }

  obtenerDescripcion() {
    this.cursos_service.getDescripcionPrograma().subscribe(response => {
      this.descripcionCurso = response[0];
      if (this.descripcionCurso) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    });
  }

  abrirModal = (idCurso) => {
    if (idCurso) {
      this.ngxSmartModalService.getModal('detalleCurso').open();
      this.obtenerCurso(idCurso);
    }
  }

  obtenerCurso(idCurso) {
    this.cursos_service.getPrograma(idCurso).subscribe(response => {
      this.curso = response[0];
    });
    this.ngxSmartModalService.setModalData(this.curso, 'detalleCurso');
  }

  obtenerCursos = () => {
    this.cursos_service.getProgramas().subscribe(response => {
      this.cursos = response;
      if (this.cursos) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    });
  }

}