import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AccordionModule } from "ngx-accordion";
import { TabsModule } from 'ngx-tabset';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ParticlesModule } from 'ngx-particle';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { CompartidoModule } from './components/compartido/compartido.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/secciones/navbar/navbar.component';
import { FooterComponent } from './components/secciones/footer/footer.component';
import { FormularioQuejaComponent } from './components/secciones/destacadas/organoControlInterno/componentes/presentaDenuncia/formularioQueja/formularioQueja.component';
import { ConvocatoriasComponent } from './components/secciones/destacadas/convocatorias/convocatorias.component';
import { DetalleNotaComponent } from './components/secciones/detalleNota/detalleNota.component';
import { AvisosPrivacidadComponent } from './components/secciones/avisosPrivacidad/avisosPrivacidad.component';
import { AudiosComponent } from './components/secciones/difusion/componentes/programasRadio/audios.component';
import { DetalleGaleriaComponent } from './components/secciones/difusion/componentes/galeria/detalleGaleria.component';
import { ChatComponent } from './components/secciones/destacadas/chat/chat.component';
import { BibliotecaComponent } from './components/secciones/destacadas/biblioteca/biblioteca.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DetalleCursoComponent } from './components/secciones/destacadas/cursos/detalleCurso.component';
import { DetalleConcursoComponent } from './components/secciones/difusion/componentes/concursos/detalleConcurso.component';
import { UnidadBibliotecaComponent } from './components/secciones/destacadas/biblioteca/unidadBiblioteca.component';
import { DetalleProgramaComponent } from './components/secciones/destacadas/programas/detalleCurso.component';

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        NavbarComponent,
        FooterComponent,
        FormularioQuejaComponent,
        ConvocatoriasComponent,
        DetalleNotaComponent,
        AvisosPrivacidadComponent,
        AudiosComponent,
        DetalleGaleriaComponent,
        ChatComponent,
        BibliotecaComponent,
        UnidadBibliotecaComponent,
        DetalleCursoComponent,
        DetalleConcursoComponent,
        DetalleProgramaComponent,        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
        AccordionModule,
        CountUpModule,
        TabsModule,
        TooltipModule,
        NgxTypedJsModule,
        ParticlesModule,
        RouterModule,
        CompartidoModule,
        NgxPaginationModule,
        NgbModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }